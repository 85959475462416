import "./pricing_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static classes = ["converted"];
  static targets = ["value", "range", "totalPrice", "unitPrice", "currency"];
  static values = {
    currency: String,
    prices: Object,
    pricePerDive: String,
    perDive: String,
  };

  initialize() {
    this.currency = this.currencyValue;
    if (this.selectedCurrency) this.currency = this.selectedCurrency.value;

    this.price = this.unitPrice;
  }

  connect() {
    this.update();
  }

  changeCurrency(e) {
    this.currency = e.target.value;
    this.update();
  }

  update() {
    this.element.classList.toggle(this.convertedClass, this.currency !== "XPF");
    this.updateValue();
    this.updateTotalPrice();
    this.updateUnitPrice();
  }

  updateValue() {
    this.valueTarget.innerText = this.moreThan30
      ? "30+"
      : this.rangeTarget.value;
  }

  updateTotalPrice() {
    this.totalPriceTarget.innerText = this.formattedPrice(
      this.moreThan30 ? this.unitPrice : this.totalPrice
    );
  }

  updateUnitPrice() {
    if (this.moreThan30) {
      this.unitPriceTarget.innerText = this.perDiveValue;
    } else {
      this.unitPriceTarget.innerText = this.pricePerDiveValue.replace(
        "${price}",
        this.formattedPrice(this.unitPrice)
      );
    }
  }

  formattedPrice(price) {
    return this.numberFormatter.format(price);
  }

  get unitPrice() {
    return this.pricesValue[this.currency];
  }

  get totalPrice() {
    return this.rangeTarget.value * this.unitPrice;
  }

  get numberFormatter() {
    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: this.currency,
      currencyDisplay: "code",
      roundingIncrement: 1,
      maximumFractionDigits: 0,
    });
  }

  get selectedCurrency() {
    return this.currencyTargets.find((currency) => currency.checked);
  }

  get moreThan30() {
    return this.rangeTarget.value == "31";
  }
}
