import "./map_component.css";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Turbo } from "@hotwired/turbo-rails";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static classes = ["imageMarker", "imageMarkerIcon", "clickableMarker"];
  static targets = ["canvas", "iconTemplate"];
  static values = {
    accessToken: String,
    bounds: Array,
    center: { type: Array, default: [-17.58603, -149.61482] },
    format: String,
    geojson: Array,
  };

  connect() {
    this.map = L.map(this.canvasTarget, {
      scrollWheelZoom: this.formatValue == "fullscreen",
    });
    this.map.setView(this.centerValue, 10);
    this.addTileLayer();

    if (!this.hasGeojsonValue && this.hasBoundsValue) this.fitToBounds();

    if (this.hasGeojsonValue) this.addGeojson();
  }

  disconnect() {
    this.map.remove();
  }

  addTileLayer() {
    L.tileLayer(
      "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token={access_token}",
      {
        attribution:
          'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
        maxZoom: 17,
        access_token: this.accessTokenValue,
      }
    ).addTo(this.map);
  }

  fitToBounds() {
    this.map.fitBounds(this.boundsValue, { padding: this.defaultPadding });
  }

  addGeojson() {
    this.geojson = L.geoJSON(this.geojsonValue, {
      onEachFeature: (feature, layer) => {
        if (feature.properties.href) {
          layer.on("click", () => {
            Turbo.visit(feature.properties.href);
          });
        }
      },
      pointToLayer: (feature, latlon) => {
        return L.marker(latlon, {
          icon: this.marker(feature),
        });
      },
    }).addTo(this.map);

    this.map.fitBounds(this.geojson.getBounds(), {
      padding: this.defaultPadding,
    });
  }

  marker(feature) {
    return this.imageMarker(feature) || this.defaultMarker;
  }

  imageMarker(feature) {
    if (!feature.properties.image) return;

    return L.divIcon({
      className: `${this.imageMarkerClass} ${
        feature.properties.href ? this.clickableMarkerClass : ""
      }`,
      html: `<img src="${feature.properties.image}" alt="${feature.properties.name}" class="${this.imageMarkerIconClass}" />`,
      iconSize: [72, 72],
      iconAnchor: [36, 36],
    });
  }

  get defaultMarker() {
    if (!this._defaultMarker)
      this._defaultMarker = L.divIcon({
        html: this.iconTemplateTarget.innerHTML,
        iconSize: [24, 24],
        iconAnchor: [12, 24],
      });

    return this._defaultMarker;
  }

  get defaultPadding() {
    return [20, 20];
  }
}
