import "./carousel_component.css";
import "flickity/dist/flickity.min.css";
import Flickity from "flickity";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  connect() {
    this.flkty = new Flickity(this.element, {
      autoPlay: true,
      pageDots: false,
      wrapAround: true,
    });
    this.flkty.resize();
  }
}
