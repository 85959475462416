import "./header_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  toggleMenu() {
    document.body.classList.toggle("has-header-menu-open");
    this.element.classList.toggle("c-header-menu-open");
  }
}
