import "./accordion_component.css";
import Accordion from "@accede-web/accordion";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  connect() {
    this.accordion = new Accordion(this.element);
    this.accordion.mount();
  }

  disconnect() {
    if (this.accordion) {
      this.accordion.unmount();
    }
  }
}
