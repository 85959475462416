import "./islands_list_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static classes = ["active", "cross"];
  static targets = ["iconTemplate", "link"];
  static values = { activeLinks: Boolean };

  initialize() {
    if (this.activeLinksValue) {
      this.iconTemplate = this.iconTemplateTarget.innerHTML;
      this.initializeAutoscroll();
    }
  }

  initializeAutoscroll() {
    this.turboFrame = this.element.closest("turbo-frame");
    this.turboFrame.addEventListener("turbo:frame-render", () =>
      this.autoscroll()
    );
  }

  toggle(e) {
    if (!this.activeLinksValue) return;

    const target = e.target.closest("a");
    const active = target.classList.contains(this.activeClass);
    this.removeActive();
    target.classList.toggle(this.activeClass, !active);
    if (!active) {
      target.insertAdjacentHTML("beforeend", this.iconTemplate);
    }
  }

  removeActive() {
    if (!this.activeLinksValue) return;

    this.linkTargets.forEach((link) => {
      link.classList.remove(this.activeClass);
      const cross = link.querySelector(`.${this.crossClass}`);
      if (cross) {
        cross.remove();
      }
    });
  }

  autoscroll() {
    this.element.scrollIntoView({ behavior: "smooth" });
  }
}
