import "./locale_switch_component.css";

import { Controller as BaseController } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export class Controller extends BaseController {
  static targets = ["button", "menu"];

  connect() {
    useClickOutside(this);
  }

  clickOutside() {
    this.close();
  }

  toggle() {
    this.isMenuClosed ? this.open() : this.close();
  }

  open() {
    this.buttonTarget.setAttribute("aria-expanded", "true");
    this.menuTarget.removeAttribute("hidden");
    this.menuTarget.querySelector("a").focus();
  }

  close() {
    if (this.isMenuClosed) return;
    this.buttonTarget.setAttribute("aria-expanded", "false");
    this.menuTarget.setAttribute("hidden", "");
    this.buttonTarget.focus();
  }

  get isMenuClosed() {
    return this.menuTarget.hidden;
  }
}
